import React from "react";
import { graphql } from "gatsby";
import IndexPage from "@/components/pages/index/index";
import SEOEn from "@/components/seo/index.en";
import SEORu from "@/components/seo/index.ru";
import { HOME_PAGE_BEFORE_SEASON_MODE } from "@/constants";
import { useRoute } from "@/utils";
import { useI18n } from "@/plugins/i18n";

export default (props) => {
  const route = useRoute();
  const { t } = useI18n();
  const isOffseason = false;
  const { pageContext } = props;

  return (
    <>
      {pageContext.langKey === "en" && (
        <SEOEn
          description={props.data.layoutHome.ogDescription?.ogDescription}
          ogImage={props.data.layoutHome.ogImage}
        />
      )}
      {pageContext.langKey === "ru" && (
        <SEORu
          description={props.data.layoutHome.ogDescription?.ogDescription}
          ogImage={props.data.layoutHome.ogImage}
        />
      )}

      <IndexPage
        {...props}
        pageMode={HOME_PAGE_BEFORE_SEASON_MODE}
        navLinks={[
          !isOffseason && {
            to: route("program"),
            children: t("routeNames.program_before_season"),
          },
          { to: route("about"), children: t("routeNames.about") },
          { to: route("faq"), children: t("routeNames.faq") },
          { to: route("archive"), children: t("routeNames.archive") },
          { to: route("contacts"), children: t("routeNames.contacts") },
          {
            href: "https://shop.netfest.ru/",
            children: t("routeNames.shop"),
          },
        ].filter(Boolean)}
      />
    </>
  );
};

export const pageQuery = graphql`
  query($locale: String!) {
    layoutHome: contentfulLayoutHome(node_locale: { eq: $locale }) {
      pageMode
      lead_text {
        lead_text
      }
      ...HomePageKeyVisuals
      program_title
      programLinkText
      partners {
        title
        images {
          fluid(
            resizingBehavior: PAD
            maxWidth: 215
            maxHeight: 70
            quality: 100
          ) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      art_dir_text {
        childMarkdownRemark {
          html
        }
      }
      artDirectors {
        name
        position
        text {
          childMarkdownRemark {
            html
          }
        }
        cover {
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        previewCover {
          fixed(width: 180, quality: 100) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
      offseasonCarousel {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shows {
        id
        title
        slug
        cover_listing_image {
          fluid(maxWidth: 1100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        author
        previewDescription {
          previewDescription
        }
        atomicTickets(limit: 4, datetimeOrder: "asc") {
          cityShortCode
          cityShortCodeEn
          datetime(formatString: "D MMM,|ddd|HH:mm", locale: $locale)
          datetimeISO
          radarioId
          url
        }
        ticketsJson {
          tickets {
            address
          }
        }
      }
      ogDescription {
        ogDescription
      }
      ogImage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
    siteSettings: contentfulSiteSettings(node_locale: { eq: $locale }) {
      subscriptionPlaceholderText
      subscriptionPlaceholderTextMobile
      subscriptionLeadText
      subscriptionCtaText
    }
  }
`;
